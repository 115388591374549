<template>
  <div class="height_banner" v-if="!accepted">
    <div class="cookie-banner">

      <p>This website uses cookies to enhance your <span class="more" @click="toggleExp">experience</span> and gather
        <span class="more" @click="toggleStats">statistics</span>. Your data remains safe and anonymous.
      </p>

      <div id="stats-text">
        <p>Cookies help to gather detailed data on how visitors interact with the site, including information such as
          page views, clicks, preferred languages, types of devices used, sources of traffic, and demographic profiles.
          This data is essential for the site to analyze and understand user behavior, enabling to continually enhance
          the
          website's functionality and content.<b> All data collected through cookies is anonymized and
            securely handled to protect your privacy.</b></p>
      </div>

      <div id="exp-text">
        <p>To enhance your experience, cookies help to understand how you interact with various elements of the site.
          This includes data on <b>language</b> to provide content in your preferred language — <b>device</b> to
          ensure the site works seamlessly across different devices (desktop, mobile,
          tablet) — <b>platform</b> to optimize performance and compatibility with different operating systems and
          browsers — <b>versatility</b> to adapt the site's layout and functionality to enhance usability and
          accessibility — <b>media</b> to manage and improve the loading and display of videos and images, ensuring high
          quality and fast load times — <b>fonts</b> to provide a visually appealing and readable typography, enhancing
          the overall user experience.<b> Your data is always anonymized and secure,
            and these insights help continually improve your browsing experience.</b>
        </p>
      </div>

      <div id="alert-text">
        <p><i>Please accept cookies to continue using this site, these cookies help to enhance users experience.<b>
              Your data is always anonymized and secure, and these insights help continually improve your browsing
              experience.</b></i>
        </p>
      </div>

      <button @click="acceptCookies">Accept</button>
      <button @click="rejectCookies" style="color: gray">Decline</button>
    </div>

  </div>

</template>

<script>
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      accepted: false,
    };
  },
  methods: {
    acceptCookies() {
      Cookies.set('cookiesAccepted', true, { expires: 365 });
      document.querySelector('.height_banner').style.opacity = "0"
      this.launchData()
      setTimeout(() => {
        this.accepted = true;
        document.querySelector('.height_banner').style.display = "none"
      }, 600)
    },
    rejectCookies() {
      // Cookies.set('cookiesRejected', true, { expires: 0.5 / (24 * 60) }); // Expires in 1 minute (1/1440 of a day)
      Cookies.set('cookiesRejected', true); // Expires in 1 minute (1/1440 of a day)
      document.querySelector('.height_banner').style.opacity = "0"

      setTimeout(() => {
        this.accepted = false;
        document.querySelector('.height_banner').style.display = "none"
      }, 600)
    },
    toggleStats() {
      var statsText = document.getElementById('stats-text');
      if (statsText.style.display === 'none' || statsText.style.display === '') {
        statsText.style.display = 'block';
      } else {
        statsText.style.display = 'none';
      }
    },
    toggleExp() {
      var expText = document.getElementById('exp-text');
      if (expText.style.display === 'none' || expText.style.display === '') {
        expText.style.display = 'block';
      } else {
        expText.style.display = 'none';
      }
    },
    launchData() {
      if (Cookies.get('cookiesAccepted')) {
        this.accepted = true;

        const script = document.createElement('script');
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=" + process.env.VUE_APP_ANALYTICS_ID;
        script.onload = () => {
          const script2 = document.createElement('script');
          script2.type = 'text/javascript';
          const scriptContent = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', '${process.env.VUE_APP_ANALYTICS_ID}');
`;
          script2.appendChild(document.createTextNode(scriptContent));
          document.head.appendChild(script2);
        };
        script.onerror = () => {
          console.log('Error cookie script.')
        };
        document.head.appendChild(script);

        // this.hamecon()
      }
    },
    hamecon() {
      const script = document.createElement('script');
      script.src = "js/blockadblock.js";
      document.head.appendChild(script);
      setTimeout(() => {
        if (document.querySelector('.banner_ad')) {
          document.querySelector('.banner_ad').style.display = "none";
        } else {
          this.showAdblockWarning();
        }
      }, 300);
    },
    adBlockNotDetected() {
      alert('AdBlock is not enabled');
    },
    // Function called if AdBlock is detected
    adBlockDetected() {
      alert('AdBlock is enabled');
    },
    showAdblockWarning() {
      const div = document.createElement('div')
      div.classList.add('warning-text')
      div.innerText = "You accepted cookies but it looks like you're using an ad blocker. Please disable it for a better experience. No ad here."
      document.body.appendChild(div)
      div.style.position = "fixed"
      div.style.bottom = "0"
      div.style.left = "0"
      div.style.fontSize = "clamp(10px, 1vw, 250px)"
      div.style.padding = "1vw"
      div.style.background = "#f0f0f0"
      div.style.borderTop = "1px solid #ccc"
      div.style.color = "black"
      div.style.width = "100vw"
      div.style.textAlign = "center"
      div.style.fontWeight = "800"
      div.style.zIndex = "9000000"
    }
  },
  mounted() {
    this.launchData()
  }
};
</script>

<style scoped>
.height_banner {
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 20000000;
  background: rgba(0, 0, 0, 0.85);
  height: 100%;
  width: 100vw;
  opacity: 1;
  transition: opacity 0.6s ease;
}

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5vw 0;
  background-color: #f0f0f0;
  border-top: 1px solid #ccc;
  color: black;
  text-align: center;
  font-size: clamp(10px, 1vw, 250px);
}

.cookie-banner p {
  width: 35vw;
  margin-left: 32.5vw;
  line-height: 1.4;
}

.cookie-banner button {
  margin: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  font-weight: 800;
  text-transform: uppercase;
  font-size: clamp(10px, 1vw, 250px);
  background: none;
}

.more {
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;
}

.cookie-banner button:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}

#stats-text,
#alert-text,
#exp-text {
  display: none;
  margin-top: 1vw;
  text-align: justify;
  font-size: clamp(10px, 0.8vw, 250px);
}

@media only screen and (max-width: 768px) {

  .cookie-banner p {
    width: 90vw;
    margin-left: 5vw;
  }

}
</style>
