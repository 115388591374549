<template>
    <div class="footer">
        <span><br />©2024 — <a href="https://closetonone.com" target="_blank"
                @click="trackClose">Closetonone</a></span>
    </div>
</template>

<script>
export default {
    methods: {
        checkFooterVisibility() {
            var footer = document.querySelector('.footer');
            var scrollTop = window.scrollY || document.documentElement.scrollTop;
            var windowHeight = window.innerHeight;
            var documentHeight = document.documentElement.scrollHeight;

            var scrollPosition = scrollTop + windowHeight;
            var triggerHeight = documentHeight * 0.9; // 80% of the document height

            if (scrollPosition >= triggerHeight) {
                footer.style.opacity = "1";
            } else {
                footer.style.opacity = "0";
            }
        },
        gtag() {
            window.dataLayer.push(arguments);
        },
        trackClose() {
            this.gtag('event', 'Click on CLOSETONONE', {
                'event_category': 'Interaction',
                'event_label': 'Click on CLOSETONONE'
            });
        }
    },
    mounted() {
        // Initialize footer opacity to 0
        var footer = document.querySelector('.footer');
        footer.style.opacity = "0";

        // Bind the checkFooterVisibility method to the scroll event
        window.addEventListener('scroll', this.checkFooterVisibility);

        // Initial check after a short delay to ensure all elements are properly rendered
        setTimeout(() => {
            this.checkFooterVisibility();
        }, 100);

        // Adjust footer position if warning-text is present
        if (document.querySelector('.warning-text')) {
            footer.style.bottom = "3.5vw";
        }
    },
    beforeUnmount() {
        // Clean up the event listener when the component is destroyed
        window.removeEventListener('scroll', this.checkFooterVisibility);
    }
}
</script>

<style scoped>
.footer {
    display: flex;
    width: 98vw;
    height: 5vh;
    position: fixed;
    z-index: 100;
    bottom: 1vh;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.footer span {
    font-weight: 800;
    font-size: 9px;
    opacity: 0.7;
    color: rgb(92, 92, 92);
    text-transform: uppercase;
    line-height: 1.3;
    font-family: "courier-prime", monospace;
    font-size: clamp(10px, 0.7vw, 150px);
}

.footer a {
    color: black;
}

.footer a:hover {
    text-decoration: underline;
}
</style>
